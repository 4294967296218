import React, { Fragment, useRef, useState, useEffect } from "react";
import {
  Button,
  Input,
  Space,
  Table,
  Tag,
  Popconfirm,
  ConfigProvider,
  Modal,
  Image,
  Spin,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddAppointmentModal,
  EditAppointmentModal,
  Header,
} from "../components/common";
import { useNavigate } from "react-router-dom";

import "../index.css";

const AppointmentPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [towers, setTowers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isAddAppointmentModalOpen, setisAddAppointmentModalOpen] =
    useState(false);
  const [openEditModalId, setOpenEditModalId] = useState(null);
  const [message, setMessage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_BASE_URL_API = process.env.REACT_APP_API_BASE_URL_API;

  const showImages = async (appointmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/appointments/${appointmentId}/images`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch appointment images");
      }
      const data = await response.json();

      // Ensure that the image URLs are correctly received
      const images = data.images.map((image) =>
        image.startsWith("http")
          ? image
          : `${API_BASE_URL_API}/storage/${image}`
      );

      setSelectedImages(images);
      setShowImageModal(true);
    } catch (error) {
      console.error("Error fetching appointment images:", error);
    }
  };

  const hideImagesModal = () => {
    setShowImageModal(false);
  };

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem("token")) {
      navigate("/login"); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      console.error(
        "Token is not present in localStorage. Redirect or show an error message."
      );
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storedToken}`,
    };

    Promise.all([
      fetch(`${API_BASE_URL}/appointments`, { headers }),
      fetch(`${API_BASE_URL}/towers`, { headers }),
      fetch(`${API_BASE_URL}/projects`, { headers }),
    ])
      .then(([appointmentsResponse, towersResponse, projectsResponse]) =>
        Promise.all([
          appointmentsResponse.json(),
          towersResponse.json(),
          projectsResponse.json(),
        ])
      )
      .then(([appointmentsData, towersData, projectsData, response]) => {
        setAppointments(appointmentsData.data);
        setTowers(towersData.data);
        setProjects(projectsData.data);
        setLoading(false);
        if (response && response.data) {
          toast.success(response.data.message, {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        if (error.response?.status === 422 && error.response?.data?.errors) {
          setLoading(false);
          const validationErrors = error.response.data.errors;
          const errorMessage = validationErrors.join(", ");
          toast.error(errorMessage, {
            position: "top-right",
          });
        } else {
          setLoading(false);
          toast.error(error.response?.data?.error || "Error fetching data.", {
            position: "top-right",
          });
        }
      });
  };

  const fetchUpdatedData = () => {
    fetch(`${API_BASE_URL}/appointments`)
      .then((appointmentsResponse) => appointmentsResponse.json())
      .then((appointmentsData) => {
        console.log("Updated Appointments:", appointmentsData);
        setAppointments(appointmentsData.data);
      })
      .catch((error) => {
        console.error("Error fetching updated data:", error);
      });
  };

  const onAddAppointmentClick = () => {
    setisAddAppointmentModalOpen(true);
  };

  const onEditAppointmentClick = (appointmentId) => {
    setOpenEditModalId(appointmentId);
  };

  const onAddSuccess = () => {
    fetchAppointmentData();
  };

  const onEditSuccess = () => {
    fetchUpdatedData();
  };

  const onDelete = (appointmentId) => {
    fetch(`${API_BASE_URL}/appointments/delete/${appointmentId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          toast.success("Appointment deleted successfully", {
            position: "top-right",
          });
          await fetchAppointmentData();
        } else {
          const errorMessage = await response.text();
          toast.error(`Error deleting appointment: ${errorMessage}`, {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        toast.error("Error deleting appointment", {
          position: "top-right",
        });
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="default"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <SearchOutlined
        style={{
          fontSize: "15px",
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : false;
    },
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "13%",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Client",
      dataIndex: "clients_name",
      key: "client",
      width: "15%",
      ...getColumnSearchProps("clients_name"),
      sorter: (a, b) => {
        const nameA = a.clients_name ? a.clients_name.toLowerCase() : "";
        const nameB = b.clients_name ? b.clients_name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contact Number",
      dataIndex: "clients_contact_number",
      key: "clients_contact_number",
      width: "15%",
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: "20%",
      render: (project) => (project && project.name ? project.name : ""),
      sorter: (a, b) => {
        const nameA =
          a.project && a.project.name ? a.project.name.toLowerCase() : "";
        const nameB =
          b.project && b.project.name ? b.project.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: "15%",
    },
    {
      title: "Seller Name",
      dataIndex: ["seller", "name"],
      key: "seller",
      width: "15%",
      ...getColumnSearchProps(["seller", "name"]),
      sorter: (a, b) => {
        const nameA = a.clients_name ? a.clients_name.toLowerCase() : "";
        const nameB = b.clients_name ? b.clients_name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sales Manager",
      dataIndex: ["sales_manager", "name"],
      key: "client",
      width: "15%",
      ...getColumnSearchProps(["sales_manager", "name"]),
      sorter: (a, b) => {
        const nameA = a.clients_name ? a.clients_name.toLowerCase() : "";
        const nameB = b.clients_name ? b.clients_name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sales Director",
      dataIndex: ["sales_director", "name"],
      key: "client",
      width: "15%",
      ...getColumnSearchProps(["sales_director", "name"]),
      sorter: (a, b) => {
        const nameA = a.clients_name ? a.clients_name.toLowerCase() : "";
        const nameB = b.clients_name ? b.clients_name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      responsive: ["md"],
      filters: [
        {
          text: "Workable",
          value: "Workable",
        },
        {
          text: "For Booking",
          value: "For Booking",
        },
        {
          text: "Closeable",
          value: "Closeable",
        },
        {
          text: "Fallout",
          value: "Fallout",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => {
        let color;
        if (status === "Closeable") {
          color = "green";
        } else if (status === "Fallout") {
          color = "red";
        } else if (status === "For Booking") {
          color = "orange";
        } else if (status === "Workable") {
          color = "cyan";
        }
        return (
          <>
            <Tag color={color} key={status}>
              {status.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: "",
      width: "5%",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: "#A98F4E",
                borderRadius: 2,

                // Alias Token
                colorBgContainer: "#A98F4E",
              },
            }}
          >
            <Button
              shape="circle"
              onClick={() => onEditAppointmentClick(record.id)}
              icon={<EditOutlined />}
              style={{
                marginRight: "10px",
              }}
            />
            <Popconfirm
              title={`Are you sure to delete?`}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => {}}
            >
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                style={{
                  marginRight: "10px",
                }}
              />
            </Popconfirm>
            <Button
              shape="circle"
              onClick={() => showImages(record.id)} // Call showImages function with appointmentId
              icon={<FileImageOutlined />}
            />
          </ConfigProvider>
        </div>
      ),
    },
  ];

  return (
    <div id="body">
      <Header pageName="Appointment" />
      <div id="start-end">
        <h1></h1>
        <Button
          onClick={onAddAppointmentClick}
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
        >
          Add Appointment
        </Button>
      </div>
      <div id="page">
        {loading ? (
          <div>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 30,
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <Table
            //   components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={appointments}
            columns={columns}
            className="w-100 h-100"
          />
        )}
        <Modal
          visible={showImageModal}
          onCancel={hideImagesModal}
          footer={null}
        >
          {selectedImages.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index}`} />
          ))}
        </Modal>
        <AddAppointmentModal
          isOpen={isAddAppointmentModalOpen}
          onClose={() => setisAddAppointmentModalOpen(false)}
          projects={projects}
          towers={towers}
          onAddSuccess={onAddSuccess} // Pass onAddSuccess as a prop
        />
        {openEditModalId !== null && (
          <EditAppointmentModal
            isOpen={true}
            onClose={() => setOpenEditModalId(null)}
            projects={projects}
            towers={towers}
            onEditSuccess={onEditSuccess}
            currentAppointment={appointments.find(
              (appointment) => appointment.id === openEditModalId
            )}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AppointmentPage;
